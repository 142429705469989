import { useEffect, useState } from 'react';
import { baseUrl, webUrl } from '../template/config';
import { EmployeeQR } from 'src/api/generate_qr';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export function useFormData() {
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [typeCode, setTypeCode] = useState('Employee Code');
  const [istemplate, setIstemplate] = useState(true);
  const [empName, setEmpName] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [stateQr, setStateQr] = useState(false);
  const [branch, setBranch] = useState('');
  const [booth, setBooth] = useState('');
  const [spoke, setSpoke] = useState('');
  const [boothList, setBoothList] = useState([]);
  const [spokeList, setSpokeList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/Booth`);
        setBoothList(response.data);
      } catch (error) {
        console.error('Error fetching booth data:', error);
      }
    };

    if (!boothList.length) {
      fetchData();
    }
  }, [boothList]);

  useEffect(() => {
    const fetchDataSpoke = async () => {
      try {
        const response = await axios.get(`${baseUrl}/Spoke`);
        setSpokeList(response.data);
      } catch (error) {
        console.error('Error fetching spoke data:', error);
      }
    };

    if (!spokeList.length) {
      fetchDataSpoke();
    }
  }, [spokeList]);

  useEffect(() => {
    if (empName) {
      setEmpName(empName);
    }
  }, [empName]);

  const handleClear = () => {
    setCode('');
    setTypeCode('Employee Code');
    setEmpName('');
    setQrCode('');
    setStateQr(false);
  };

  const handleChange = (event, type) => {
    setStateQr(false);
    if (type === 'TypeCode') {
      setTypeCode(event.target.value);
    } else if (type === 'Code') {
      setCode(event.target.value);
    } else if (type === 'isTemplate') {
      setIstemplate(event.target.checked);
    } else if (type === 'Branch') {
      setBranch(event.target.value);
    } else if (type === 'Spoke') {
      setSpoke(event.target.value);
    } else {
      console.log('Error handleChange');
    }
  };

  const handleChangeBranch = (event, data) => {
    setStateQr(false);
    event.preventDefault();
    data && data.value && setBranch(data.value);
    data && data.label && setEmpName(data.label);
  };

  const handleChangeSpoke = (event, data) => {
    setStateQr(false);
    event.preventDefault();
    data && data.branchCode && setSpoke(data.branchCode);
    data && data.branchName && setEmpName(data.branchName);
  };

  const handleChangeBooth = (event, data) => {
    setStateQr(false);
    data && data.boothNo && setBooth(data.boothNo);
    data && data.boothName && setEmpName(data.boothName);
  };

  // submit group
  const handleSubmitBranch = async (event, type) => {
    event.preventDefault();
    const surveyUrl = webUrl;

    const urlParam =
      typeCode === 'Employee Code'
        ? `channel=${type}&empcode=${code}&type=empcode`
        : `channel=${type}&branchcode=${code}&type=branch`;
    const encrypt = encryptWithCryptoJS(urlParam);
    const url = surveyUrl + encrypt;

    try {
      setError('');
      const checkType = typeCode === 'Employee Code' ? 'employee' : 'branch';
      const emp = await EmployeeQR(code, checkType);
      if (typeCode === 'Employee Code') {
        if (code.length < 6 || code.length > 8) {
          setError('Code length must be 6 to 8 cahracter!');
        } else {
          if (emp.status === 200 && emp.data) {
            setError('');
            setStateQr(true);
            setEmpName(emp.data.empName);
            setQrCode(url);
          }
        }
      } else {
        if (emp.status === 200 && emp.data) {
          setError('');
          setStateQr(true);
          setEmpName(emp.data.branchName);
          setQrCode(url);
        }
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const handleSubmitBooth = async (event, type) => {
    event.preventDefault();
    const surveyUrl = webUrl;
    const urlParam = `channel=${type}&boothcode=${booth}`;
    const encrypt = encryptWithCryptoJS(urlParam);
    const url = surveyUrl + encrypt;

    try {
      setError('');
      setStateQr(true);
      setQrCode(url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitWisdom = async (event, type) => {
    event.preventDefault();
    const surveyUrl = webUrl;
    const urlParam = `channel=${type}&branchcode=${branch}`;
    const encrypt = encryptWithCryptoJS(urlParam);
    const url = surveyUrl + encrypt;
    try {
      if (branch != null) {
        setError('');
        setStateQr(true);
        // setEmpName(branchName);
        setQrCode(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitSpoke = async (event, type) => {
    event.preventDefault();
    const surveyUrl = webUrl;
    const urlParam = `channel=${type}&branchcode=${spoke}`;
    const encrypt = encryptWithCryptoJS(urlParam);
    const url = surveyUrl + encrypt;

    try {
      if (spoke != null) {
        setError('');
        setStateQr(true);
        setQrCode(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function encryptWithCryptoJS(plainText) {
    const key = CryptoJS.enc.Utf8.parse('qrsurveyfeedback');
    const iv1 = CryptoJS.enc.Utf8.parse('qrsurveyfeedback');
    var encrypted = CryptoJS.AES.encrypt(plainText, key, {
      keySize: 16,
      iv: iv1,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    encrypted = encrypted
      .toString()
      .replace(/\+/g, 'p1L2u3S')
      .replace(/\//g, 's1L2a4S')
      .replace(/=/g, 'e1Q2u3A');
    encrypted = encrypted.split('').reverse().join('');

    return encrypted;
  }

  return {
    istemplate,
    code,
    typeCode,
    branch,
    booth,
    boothList,
    spokeList,
    empName,
    qrCode,
    stateQr,
    error,
    handleClear,
    handleChange,
    handleChangeBranch,
    handleChangeBooth,
    handleChangeSpoke,
    handleSubmitBranch,
    handleSubmitBooth,
    handleSubmitWisdom,
    handleSubmitSpoke,
  };
}
